


















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class NewUser extends Vue {
  @Getter("profile/isAuthenticated") isAuthenticated!: boolean;
}
